export default function (data) {
  const sd =
    typeof data == Array
      ? data.map((o) => {
          return {
            type: "application/ld+json",
            innerHTML: JSON.stringify(o),
          };
        })
      : {
          type: "application/ld+json",
          innerHTML: JSON.stringify(data),
        };

  useHead({
    script: sd,
  });
}
